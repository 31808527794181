import React from "react"

import { Link } from "gatsby"

import { useStaticQuery, graphql } from "gatsby"

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export default props => {

    const pageUrlArray = props.pageUrl.split('/').slice(1).slice(0, -1)

    const blogTitle = props.blogTitle

    const data = useStaticQuery(graphql`
        query {
            bigcate:allContentfulCategory(filter: {navadd: {eq: "add"}}) {
                edges {
                    node {
                        categorySlug
                        categoryS1
                        categoryS2
                        categoryS3
                        categoryS4
                    }
                }
            }
        }
  `)

    return (<>

        <div className="topicpath container">
            <ul>
                <li><Link to={`/`}>TOP</Link></li>

                {data.bigcate.edges.map(bigcate => (<>
                    {pageUrlArray.map(i => (<>
                        {pageUrlArray[i] === bigcate.node.categoryS1 && (
                            <li><ChevronRightIcon /><Link to={`/${bigcate.node.categorySlug}/`}>{bigcate.node.categorySlug}</Link></li>
                        )}
                        {pageUrlArray[i] === bigcate.node.categoryS2 && (
                            <li><ChevronRightIcon /><Link to={`/${bigcate.node.categorySlug}/`}>{bigcate.node.categorySlug}</Link></li>
                        )}
                        {pageUrlArray[i] === bigcate.node.categoryS3 && (
                            <li><ChevronRightIcon /><Link to={`/${bigcate.node.categorySlug}/`}>{bigcate.node.categorySlug}</Link></li>
                        )}
                        {pageUrlArray[i] === bigcate.node.categoryS4 && (
                            <li><ChevronRightIcon /><Link to={`/${bigcate.node.categorySlug}/`}>{bigcate.node.categorySlug}</Link></li>
                        )}
                    </>))}
                </>))}

                {blogTitle && (<>
                    <li><ChevronRightIcon /><Link to={`/${pageUrlArray[0]}/`}>{pageUrlArray[0]}</Link></li>
                    <li><ChevronRightIcon />{blogTitle}</li>
                </>)}
                {!blogTitle && (
                    <li><ChevronRightIcon />{pageUrlArray[0]}</li>
                )}
            </ul>
        </div>
    </>)

}


