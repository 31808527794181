import React from "react"
import Layout from "../components/layout"
import Topicpath from "../components/topicpath"
import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import { faRedo } from "@fortawesome/free-solid-svg-icons"

import { graphql, Link } from "gatsby"

import Img from "gatsby-image/withIEPolyfill"

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import styled from 'styled-components';

export const query = graphql`
    query($catid: String!, $categoryS1: String!, $categoryS2: String!, $categoryS3: String!, $categoryS4: String!, $thumbnail: String!) {
        cate:allContentfulBlogPost(sort: {fields: createdAt, order: ASC}, filter: {category: {elemMatch: {id: {eq: $catid}}}}) {
            edges {
                node {
                    title
                    id
                    eyecatch {                        
                        fluid(maxWidth: 500) {
                          ...GatsbyContentfulFluid_withWebp
                        }
                        description
                    }
                    slug
                    publishDate
                    publishDateJp:publishDate(formatString: "YYYY年MM月DD日")
                    updatedAtJp:updatedAt(formatString: "YYYY年MM月DD日")
                    updatedAt
                    
                }
            }
        }
        acc1:allContentfulCategory(sort: {fields: createdAt, order: ASC}, filter: {categorySlug: {eq: $categoryS1}}) {
            edges {
                node {
                    category
                    id
                    categorySlug
                    blogpost {
                        title
                        slug
                        publishDate
                        publishDateJp:publishDate(formatString: "YYYY年MM月DD日")
                        updatedAtJp:updatedAt(formatString: "YYYY年MM月DD日")
                        updatedAt
                    }
                    thumbnail {
                        fluid(maxWidth: 500) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                   
                }
            }
        }
        acc2:allContentfulCategory(sort: {fields: createdAt, order: ASC}, filter: {categorySlug: {eq: $categoryS2}}) {
            edges {
                node {
                    category
                    id
                    categorySlug
                    blogpost {
                        title
                        slug
                        publishDate
                        publishDateJp:publishDate(formatString: "YYYY年MM月DD日")
                        updatedAtJp:updatedAt(formatString: "YYYY年MM月DD日")
                        updatedAt
                    }
                    thumbnail {
                        fluid(maxWidth: 500) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                   
                }
            }
        }
        acc3:allContentfulCategory(sort: {fields: createdAt, order: ASC}, filter: {categorySlug: {eq: $categoryS3}}) {
            edges {
                node {
                    category
                    id
                    categorySlug
                    blogpost {
                        title
                        slug
                        publishDate
                        publishDateJp:publishDate(formatString: "YYYY年MM月DD日")
                        updatedAtJp:updatedAt(formatString: "YYYY年MM月DD日")
                        updatedAt
                    }
                    thumbnail {
                        fluid(maxWidth: 500) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                   
                }
            }
        }
        acc4:allContentfulCategory(sort: {fields: createdAt, order: ASC}, filter: {categorySlug: {eq: $categoryS4}}) {
            edges {
                node {
                    category
                    id
                    categorySlug
                    blogpost {
                        title
                        slug
                        publishDate
                        publishDateJp:publishDate(formatString: "YYYY年MM月DD日")
                        updatedAtJp:updatedAt(formatString: "YYYY年MM月DD日")
                        updatedAt
                    }
                    thumbnail {
                        fluid(maxWidth: 500) {
                            ...GatsbyContentfulFluid_withWebp
                        }
                    }
                   
                }
            }
        }
        thumb:file(relativePath: {eq: $thumbnail}) {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
        }
    }
`

const AccordionStyle = styled(Accordion)`
    box-shadow: none!important;
    .MuiButtonBase-root,
    .MuiAccordionDetails-root {
        padding: 0!important;
    }
    .MuiAccordionSummary-content {
        margin: 0!important;
    }
`

export default ({ location, data, pageContext }) => {

    let pageTitleObj;
    let pageDescObj;

    if (pageContext.catslug.indexOf(`lesson`) > -1) {
        pageTitleObj = `無料ファッション本格講座`
        pageDescObj =`ファッション初心者～上級者まで幅広く網羅した無料講座。本質的な部分に絞って解説しています。脱ダサしたいだけなら学習期間は１週間です。最速で効率的に学んで結果を出したい。ファッション理論・法則を深く理解したい。無料で学びたい。という方にオススメの講座。`
    } else if (pageContext.catslug.indexOf(`beginner1`) > -1) {
        pageTitleObj = `一週間集中ファッション無料本格入門講座【基礎編】`
        pageDescObj = `最重要要素に絞った本質的で濃い内容になっています。解りやすいだけの浅い知識や理論、俗説はあまり役に立たないので、難しい本物の知識や理論を解りやすく深く掘り下げています。オシャレになりたい、でも何から学べばいいか分からない。最速で効率的に学んで結果を出したい。ファッション理論・法則を深く理解したい。無料で学びたい。という方にオススメの講座。`
    } else if (pageContext.catslug.indexOf(`beginner2`) > -1) {
        pageTitleObj = `一週間集中ファッション無料本格入門講座【応用編】`
        pageDescObj = `ファッションを仕事にする前に、誰かに影響を与えインフルエンサー等憧れになる前に、極める為に、必要な基礎を理解する無料講座。最速で効率的に学んで結果を出したい。ファッション理論・法則を深く理解したい。無料で学びたい。という方にオススメの講座。`
    } else {
        pageTitleObj = `「${pageContext.catname}」カテゴリー`
        pageDescObj = `「${pageContext.catname}」カテゴリーの記事です。`
    }

    return <>
        <SEO
            pagetitle={pageTitleObj}
            pagedesc={pageDescObj}
            pagepath={location.pathname}
        />
        <Layout>
            <div className="eyecatch">
                <figure>
                    <Img fluid={data.thumb.childImageSharp.fluid} style={{ height: "100%" }} alt={`${pageContext.catslug}: ${pageContext.catname}TOP`} />
                </figure>
                <h1 className="bar">{pageContext.catname}</h1>
            </div>

            <Topicpath pageUrl={location.pathname} />

            <section className="content categorylist">

                <div className="container">
                    <article className="wrapper">
                        <ul>
                            {data.cate.edges.map(blog => (
                                <li>
                                    <Link to={`/undefined/${blog.node.slug}/`}>
                                        <Img fluid={blog.node.eyecatch.fluid} style={{ height: "100%" }} alt={blog.node.eyecatch.description} />
                                        <div className="inner">
                                            <span>{blog.node.title}</span>
                                            <time dateTime={blog.node.publishDate}><FontAwesomeIcon icon={faClock} />{blog.node.publishDateJp}</time>
                                            <time dateTime={blog.node.updatedAt}><FontAwesomeIcon icon={faRedo} />{blog.node.updatedAtJp}</time>
                                        </div>
                                        <ChevronRightIcon />
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </article>
                </div>

                {pageContext.categoryS1 !== "null" && (<div className="container">

                    {data.acc1.edges.map(cat => (
                        <article key={cat.node.id}>
                            <AccordionStyle TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary>
                                    <h2 className="bar2">{cat.node.category}<KeyboardArrowDownIcon /></h2>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="wrapper">
                                        <p className="topTitle"><Link to={`/${cat.node.categorySlug}/`}>{cat.node.category} TOP<ChevronRightIcon /></Link></p>
                                        <ul>
                                            {cat.node.blogpost.map(catlower => (
                                                <li><Link to={`/undefined/${catlower.slug}/`}>
                                                    <Img fluid={cat.node.thumbnail.fluid} style={{ height: "100%" }} alt={cat.node.category} />
                                                    <div className="inner">
                                                        <span>{catlower.title}</span>
                                                        <time dateTime={catlower.publishDate}><FontAwesomeIcon icon={faClock} />{catlower.publishDateJp}</time>
                                                        <time dateTime={catlower.updatedAt}><FontAwesomeIcon icon={faRedo} />{catlower.updatedAtJp}</time>
                                                    </div>
                                                    <ChevronRightIcon />
                                                </Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </AccordionStyle>
                        </article>
                    ))}

                </div>)}

                {pageContext.categoryS2 !== "null" && (<div className="container">

                    {data.acc2.edges.map(cat => (
                        <article key={cat.node.id}>
                            <AccordionStyle TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary>
                                    <h2 className="bar2">{cat.node.category}<KeyboardArrowDownIcon /></h2>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="wrapper">
                                        <p className="topTitle"><Link to={`/${cat.node.categorySlug}/`}>{cat.node.category} TOP<ChevronRightIcon /></Link></p>
                                        <ul>
                                            {cat.node.blogpost.map(catlower => (
                                                <li><Link to={`/undefined/${catlower.slug}/`}>
                                                    <Img fluid={cat.node.thumbnail.fluid} style={{ height: "100%" }} alt={cat.node.category} />
                                                    <div className="inner">
                                                        <span>{catlower.title}</span>
                                                        <time dateTime={catlower.publishDate}><FontAwesomeIcon icon={faClock} />{catlower.publishDateJp}</time>
                                                        <time dateTime={catlower.updatedAt}><FontAwesomeIcon icon={faRedo} />{catlower.updatedAtJp}</time>
                                                    </div>
                                                    <ChevronRightIcon />
                                                </Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </AccordionStyle>
                        </article>
                    ))}

                </div>)}

                {pageContext.categoryS3 !== "null" && (<div className="container">

                    {data.acc3.edges.map(cat => (
                        <article key={cat.node.id}>
                            <AccordionStyle TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary>
                                    <h2 className="bar2">{cat.node.category}<KeyboardArrowDownIcon /></h2>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="wrapper">
                                        <p className="topTitle"><Link to={`/${cat.node.categorySlug}/`}>{cat.node.category} TOP<ChevronRightIcon /></Link></p>
                                        <ul>
                                            {cat.node.blogpost.map(catlower => (
                                                <li><Link to={`/undefined/${catlower.slug}/`}>
                                                    <Img fluid={cat.node.thumbnail.fluid} style={{ height: "100%" }} alt={cat.node.category} />
                                                    <div className="inner">
                                                        <span>{catlower.title}</span>
                                                        <time dateTime={catlower.publishDate}><FontAwesomeIcon icon={faClock} />{catlower.publishDateJp}</time>
                                                        <time dateTime={catlower.updatedAt}><FontAwesomeIcon icon={faRedo} />{catlower.updatedAtJp}</time>
                                                    </div>
                                                    <ChevronRightIcon />
                                                </Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </AccordionStyle>
                        </article>
                    ))}

                </div>)}

                {pageContext.categoryS4 !== "null" && (<div className="container">

                    {data.acc4.edges.map(cat => (
                        <article key={cat.node.id}>
                            <AccordionStyle TransitionProps={{ unmountOnExit: true }}>
                                <AccordionSummary>
                                    <h2 className="bar2">{cat.node.category}<KeyboardArrowDownIcon /></h2>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className="wrapper">
                                        <p className="topTitle"><Link to={`/${cat.node.categorySlug}/`}>{cat.node.category} TOP<ChevronRightIcon /></Link></p>
                                        <ul>
                                            {cat.node.blogpost.map(catlower => (
                                                <li><Link to={`/undefined/${catlower.slug}/`}>
                                                    <Img fluid={cat.node.thumbnail.fluid} style={{ height: "100%" }} alt={cat.node.category} />
                                                    <div className="inner">
                                                        <span>{catlower.title}</span>
                                                        <time dateTime={catlower.publishDate}><FontAwesomeIcon icon={faClock} />{catlower.publishDateJp}</time>
                                                        <time dateTime={catlower.updatedAt}><FontAwesomeIcon icon={faRedo} />{catlower.updatedAtJp}</time>
                                                    </div>
                                                    <ChevronRightIcon />
                                                </Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </AccordionStyle>
                        </article>
                    ))}

                </div>)}

            </section>


        </Layout>
    </>
}